import { FormEvent, useCallback, useState } from 'react';

import arrow from '../../assets/intoto-arrow.svg';
import language from '../../language';

import Map from '../Map/Map';

import './App.css';

function markersToQueryString(markers: Record<string, MarkerLocation>): string {
  return Object.values(markers).reduce((acc, marker, index) => {
    const name = marker.name.trim();

    return [
      acc,
      `loc_${index + 1}_name=${encodeURIComponent(name === '' ? language.namePlaceholderPopup : name)}`,
      `loc_${index + 1}_long=${encodeURIComponent(marker.lngLat[0])}`,
      `loc_${index + 1}_lat=${encodeURIComponent(marker.lngLat[1])}`,
    ].join('&')
  }, '').substr(1);
}

function App() {
  const [markers, setMarkers] = useState<Record<string, MarkerLocation>>({});
  const [mapLocation, setMapLocation] = useState<[number, number]>();
  const [showMarker, setShowMarker] = useState(false);

  const minLocations = 1;
  const maxLocations = 5;

  const canAddMarker = Object.values(markers).length < maxLocations;
  const canSubmit = Object.values(markers).length >= minLocations;

  const addMarker = useCallback((location: [number, number]) => {
    if (!canAddMarker) {
      return;
    }

    setMarkers({
      ...markers,
      [(+new Date()).toString()]: { name: '', lngLat: location }},
    );
  }, [canAddMarker, markers]);

  const setMarkerName = useCallback((key: string, name: string) => {
    setMarkers({
      ...markers,
      [key]: {
        ...markers[key],
        name,
      },
    })
  }, [markers]);

  const removeMarker = useCallback((key: string) => {
    const newMarkers = { ...markers };
    delete newMarkers[key];

    setMarkers(newMarkers);
  }, [markers]);

  const setMarkerLocation = useCallback((key: string, location: [number, number]) => {
    setMarkers({ ...markers, [key]: { ...markers[key], lngLat: location }})
  }, [markers]);

  const showHoverMarker = (show: boolean) => {
    if (window.innerWidth > 800) {
      setShowMarker(show);
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!canSubmit) {
      alert(language.submitWarning);
      return false;
    }

    const url = process.env.REACT_APP_TYPEFORM_URL;
    const qs = markersToQueryString(markers);

    window.location.href = url + qs;

    return false;
  };

  return (
    <div className="App">
      <aside>
        <form onSubmit={(e) => { e.preventDefault(); return false; }}>
          <fieldset>
            <button
              className="button"
              type="button"
              disabled={!canAddMarker}
              onMouseOver={() => showHoverMarker(true)}
              onMouseOut={() => showHoverMarker(false)}
              onClick={() => {
                if (mapLocation) {
                  addMarker(mapLocation);
                }
              }}
            >
              {language.addLocation}
            </button>
          </fieldset>
          {Object.entries(markers).map(([key, marker]) => (
            <div className="mapMarker" key={key}>
              <img src={arrow} alt="Location" />
              <fieldset>
                <label>Name:</label>
                <input
                  type="text"
                  value={marker.name}
                  placeholder={language.namePlaceholder}
                  onChange={(e) => setMarkerName(key, e.target.value)}
                />
              </fieldset>
              <button
                type="button"
                onClick={() => removeMarker(key)}
              >
                ×
              </button>
            </div>
          ))}
          <fieldset className="submit">
            <button
              className="button submit"
              type="button"
              onClick={onSubmit}
              disabled={!canSubmit}
            >
              {language.submit}
            </button>
          </fieldset>
        </form>
      </aside>
      <main>
        <div className="marker marker-fake" style={{ opacity: showMarker ? 0.5 : 0 }} />
        <Map
          markers={markers}
          addMarker={addMarker}
          setMarkerLocation={setMarkerLocation}
          onMapMove={setMapLocation}
        />
      </main>
    </div>
  );
}

export default App;
