import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { MapMouseEvent } from 'mapbox-gl';

// @ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import language from '../../language';

import './Map.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
mapboxgl.workerClass = MapboxWorker;

const defaultLngLat: { lng: number; lat: number; } = { lng: 8.5117, lat: 59.4641 };

interface MapProps {
  markers: Record<string, MarkerLocation>;
  addMarker: (location: [number, number]) => void;
  setMarkerLocation: (key: string, location: [number, number]) => void;
  onMapMove: (location: [number, number]) => void;
}

const Map: FunctionComponent<MapProps> = ({ markers, addMarker, setMarkerLocation, onMapMove }) => {
  const debounceTime = useRef(0);
  const mapMarkers = useRef<mapboxgl.Marker[]>([]);
  const [lngLat, setLngLat] = useState(defaultLngLat);

  const map = useRef<mapboxgl.Map>();

  const addNewMarker = useCallback((location: [number, number] = [lngLat.lng, lngLat.lat]) => {
    if (!map.current) return;

    addMarker(location);
  }, [addMarker, lngLat.lat, lngLat.lng]);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: 'map-container',
      style: process.env.REACT_APP_MAPBOX_STYLE || 'mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g',
      center: defaultLngLat,
      zoom: 6
    });

    function syncMapState() {
      const lng = parseFloat(map.current?.getCenter().lng.toFixed(4) || '0');
      const lat = parseFloat(map.current?.getCenter().lat.toFixed(4) || '0');

      setLngLat({ lng, lat });
    }

    map.current.on('move', () => {
      if (debounceTime.current) {
        window.clearTimeout(debounceTime.current);
      }

      debounceTime.current = window.setTimeout(syncMapState, 500);
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    function getLatLon(position: GeolocationPosition) {
      const { latitude, longitude } = position.coords;

      setLngLat({ lng: longitude, lat: latitude });
      map.current?.setZoom(10);
    }

    navigator.geolocation.getCurrentPosition(getLatLon);

    return () => map.current?.remove();
  }, []);

  useEffect(() => {
    map.current?.setCenter([lngLat.lng, lngLat.lat]);
    onMapMove([lngLat.lng, lngLat.lat]);
  }, [lngLat.lng, lngLat.lat, onMapMove]);

  useEffect(() => {
    const handleClick = (event: MapMouseEvent) => {
      // @ts-ignore
      const originElement = event.originalEvent.originalTarget
        // @ts-ignore
        || event.originalEvent.toElement
        || { classList: { contains: () => false } };

      if (originElement.classList.contains('marker')) {
        return;
      }

      addNewMarker([event.lngLat.lng, event.lngLat.lat]);
    };

    map.current?.on('click', handleClick);

    return () => {
      map.current?.off('click', handleClick);
    };
  }, [addNewMarker]);

  useEffect(() => {
    if (!map.current) return;

    mapMarkers.current.forEach((marker) => marker.remove());
    mapMarkers.current = [];

    Object.entries(markers).forEach(([key, value]) => {
      if (!map.current) return;

      const popup = new mapboxgl.Popup({ offset: 5, anchor: 'top' })
        .setHTML(`
        <div class="popup-container">
            <div class="popup-name">${value.name || language.namePlaceholderPopup}</div>
            <div class="popup-gps">GPS: ${value.lngLat.map(v => v.toFixed(4)).join(',')}</div>
        </div>
        `);

      const el = document.createElement('div');
      el.className = 'marker';

      // make a marker for each feature and add to the map
      const marker = new mapboxgl.Marker({
        element: el,
        anchor: 'bottom',
        draggable: true,
        offset: [0, 2],
      })
        .setLngLat(value.lngLat)
        .setPopup(popup)
        .addTo(map.current);

      marker.on('dragend', () => {
        const lngLat = marker.getLngLat();
        setMarkerLocation(key, [lngLat.lng, lngLat.lat]);
      });

      mapMarkers.current.push(marker);
    })
  }, [markers, setMarkerLocation])

  return (
    <div id="map-container" className="map-container" />
  );
};

export default Map;